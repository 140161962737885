/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

import logo from 'images/PinShirt_logo.svg';
import bbSmall from 'images/pinshirt-og.png'
import favIcon from 'images/favicon.ico';
import arrowdown from 'images/arrow-down.svg';

import appleStoreBadge from 'images/apple-store-badge.png';
import googlePlayStoreBadge from 'images/google-play-badge.png';
import pinshirtMap1 from 'images/pinshirt_map.png';
import pinshirtMap2 from 'images/pinshirt_map2.png';

import screen1 from 'images/screen1.png';
import screen2 from 'images/screen2.png';
import content1 from 'images/content1.png';
import content2 from 'images/content2.png';
import sizeGuide from 'images/size-guide.png';
import eco from 'images/eco-friendly.png';

import app1 from 'images/app1.png';
import app2 from 'images/app2.png';
import app3 from 'images/app3.png';
import app4 from 'images/app4.png';

import partner2 from 'images/partner2.png';
import partner3 from 'images/partner3.png';
import partner4 from 'images/partner4.png';
import partner1 from 'videos/partner2.mp4';
import partner1Small from 'videos/partner3_small.mp4';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAa7BG5i3u66PQX_c6bLAJhJXWi2v91TMA",
  authDomain: "pinshirt-me-web.firebaseapp.com",
  projectId: "pinshirt-me-web",
  storageBucket: "pinshirt-me-web.appspot.com",
  messagingSenderId: "265258868053",
  appId: "1:265258868053:web:cebc3a05cb956688a7e3dc",
  measurementId: "G-KYMMJCRV1K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

